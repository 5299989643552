import { qs } from "../utils/dom"

export class PhaseCircles {
  constructor(els) {
    this.els = els
    this.observer = new IntersectionObserver(this.observerCallback)

    this.circleMap = new Map()

    this.els.forEach((el) => {
      const circle = qs("[data-phase-circle-progress]", el)
      const cirlceLength = circle.getTotalLength()

      circle.style.setProperty("stroke-dasharray", cirlceLength)
      circle.style.setProperty("stroke-dashoffset", cirlceLength)

      this.circleMap.set(el, { circle, cirlceLength })

      this.observer.observe(el)
    })
  }

  observerCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.startAnimation(entry.target)
        this.observer.unobserve(entry.target)
      }
    })
  }

  startAnimation(element) {
    const { circle, cirlceLength } = this.circleMap.get(element)

    element.classList.add("animate")
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        circle.style.setProperty(
          "stroke-dasharray",
          cirlceLength + cirlceLength * 0.75,
        )
        circle.style.setProperty("stroke-dashoffset", cirlceLength)
      })
    })
  }
}
