import { qs, qsa } from "../utils/dom"

export class Regions {
  constructor(el) {
    this.regionsEl = el

    this.labels = qsa("label", this.regionsEl)
    this.svgGroups = qsa("svg g", this.regionsEl)
    this.regions = this.labels.map((label) => {
      const code = label.dataset.toggle
      return {
        code,
        label,
        checkbox: qs(`#${label.htmlFor}`, this.regionsEl),
        svgGroup: this.svgGroups.find((g) => g.dataset.toggle === code),
      }
    })

    /*
     * Match the styles with the initial selection.
     * Some browser store the selection before a reload.
     */
    this.regions.forEach((region) => {
      if (region.checkbox.checked) {
        region.label.classList.add("active")

        if (region.svgGroup) {
          region.svgGroup.classList.add("active")
        }
      }
    })

    this.link()
  }

  activate(region) {
    region.checkbox.click()
    region.label.classList.toggle("active")

    if (region.svgGroup) {
      region.svgGroup.classList.toggle("active")
    }
  }

  link() {
    this.regions.forEach((region) => {
      const clickHandler = (e) => {
        e.preventDefault()
        this.activate(region)
      }

      region.label.addEventListener("click", clickHandler)

      if (region.svgGroup) {
        region.svgGroup.addEventListener("click", clickHandler)

        const mouseEnterHandler = () => {
          region.svgGroup.classList.add("hover")
        }

        const mouseLeaveHandler = () => {
          region.svgGroup.classList.remove("hover")
        }

        region.label.addEventListener("mouseenter", mouseEnterHandler)
        region.label.addEventListener("mouseleave", mouseLeaveHandler)
      }
    })
  }

  reset() {
    this.regions.forEach((region) => {
      region.label.classList.remove("active")

      if (region.svgGroup) {
        region.svgGroup.classList.remove("active")
      }
    })
  }
}
