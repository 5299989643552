import { h, Fragment, render } from "preact"
import { qs } from "../utils/dom"

export class Lightboxes {
  constructor(lbs) {
    this.imgs = []
    this.current = {}
    lbs.forEach((lb, i) => {
      this.imgs.push({
        id: `lb_${i}`,
        src: lb.dataset.lightbox,
        caption: lb.dataset.caption,
        copyright: lb.dataset.copyright,
      })
      lb.addEventListener("click", (e) => {
        e.preventDefault()
        this.open(`lb_${i}`)
      })
    })
    this.root = document.body.appendChild(document.createElement("div"))
    this.root.classList.add("lightbox-area")
    window.addEventListener("keyup", (e) => {
      if (this.root.classList.contains("active")) {
        if (e.key == "Escape") this.close(e)
        if (e.key == "ArrowRight") this.next(e)
        if (e.key == "ArrowLeft") this.previous(e)
      }
    })
    render(this.LightboxArea(this.imgs), this.root)
  }

  open = (id) => {
    this.root.classList.add("active")
    this.current.classList && this.current.classList.remove("active")
    this.current = qs(`#${id}`)
    this.current.classList.add("active")
    this.root.focus()
  }

  close = (e) => {
    e.preventDefault()
    this.root.classList.remove("active")
    this.current.classList.remove("active")
  }

  switch(e, target) {
    e.preventDefault()
    let index = this.imgs.findIndex((lb) => {
      return lb.id === this.current.id
    })
    if (index + target > this.imgs.length - 1) {
      this.open(this.imgs[0].id)
    } else if (index + target < 0) {
      this.open(this.imgs[this.imgs.length - 1].id)
    } else {
      this.open(this.imgs[index + target].id)
    }
  }

  LightboxArea = (imgs) => {
    return (
      <div class="lightbox__wrapper">
        <div class="lightbox__inner">
          <div class="lightbox__control">
            <a
              href=""
              class="lightbox__previous"
              onClick={(e) => this.switch(e, -1)}
            >
              <icon class="icon arrow-white-left lightbox-icon" />
            </a>
            <a href="" class="lightbox__close" onClick={this.close}>
              <icon class="icon close lightbox-icon" />
            </a>
            <a
              href=""
              class="lightbox__next"
              onClick={(e) => this.switch(e, 1)}
            >
              <icon class="icon arrow-white lightbox-icon" />
            </a>
          </div>
          {imgs.map((img) => (
            <Fragment key={img.id}>
              <div id={img.id} key={img.id} class="lightbox lightbox__img-wrap">
                <img class="lightbox__img" src={img.src} />
                <div class="lightbox__caption">
                  {img.caption && <span class="caption">{img.caption}</span>}
                  {img.copyright && (
                    <span class="copyright">{img.copyright}</span>
                  )}
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    )
  }
}
