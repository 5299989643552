import { h } from "preact"

export const FoC = ({ objects }) => {
  return objects.map((o) => (
    <a
      href={o.url}
      title={o.name}
      id={o.id}
      class="card cell box small-12 medium-4 large-3 text-left"
      key={o.id}
    >
      <div class="card-label">{o.name}</div>
      <div class="card-info__bottom">
        <div class="card-info__bottom-left">
          {o.phases.map((p, idx) => (
            <icon class={`filter-icon phase-icon p_${p}`} key={idx} />
          ))}
        </div>
      </div>
    </a>
  ))
}
