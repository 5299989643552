import { h } from "preact"

export const Projects = ({ objects }) => {
  return objects.map((o) => (
    <a
      class="card cell small-12 medium-4 large-3 text-left"
      href={o.url}
      key={o.id}
    >
      <div class={o.videos.all && "project-card__embedded-wrap"}>
        <img src={o.image1} alt={o.name} title={o.name} />
        {o.videos.all && (
          <icon class="icon play-circle project-card__embedded-icon" />
        )}
      </div>

      <div class="card-label">{o.name}</div>
    </a>
  ))
}
