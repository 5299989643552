// special Gallery from SRG Insider
import { SwipeDetector } from "swipedetector"

export function modulo(p, q) {
  // A modulo function which actually returns a value with the sign of the
  // divisor.
  let remainder = p % q
  return q > 0 ? (remainder + q) % q : (remainder - q) % q
}

export function applyTransform(element, transform, transition) {
  element.style.webkitTransform =
    element.style.MozTransform =
    element.style.msTransform =
    element.style.OTransform =
    element.style.transform =
      transform
  element.style.WebkitTransition = element.style.MozTransition = transition
}

export class Gallery {
  constructor(element) {
    this.element = element
    this.slider = this.element.querySelector("[data-slider]")
    this.slides = this.element.querySelectorAll("[data-slide]")
    this.thumbsContainer = this.element.querySelector("[data-thumbs]")

    this._current = null
    this._interval = null

    this._setWidthsAndPositions()

    this.element.dataset.hideControls = this.slides.length <= 1

    this._createThumbs()

    this.thumbs = this.element.querySelectorAll("[data-thumb]")

    this._setEventListeners()

    this.reveal(0)
  }

  move(direction) {
    this.reveal(this._current + direction)
  }

  reveal(index) {
    this.slides.forEach((slide, index) => {
      if (index < this._current || index >= this._current + 1)
        slide.dataset.slidePosition = -1
      else slide.dataset.slidePosition = index - this._current
    })

    this.thumbs[this._current] &&
      this.thumbs[this._current].removeAttribute("data-current")

    this._current = modulo(index, this.slides.length)

    applyTransform(
      this.slider,
      `translate3d(-${(this.width * this._current) / 1}px, 0, 0)`,
      `transform 0.5s ease-in-out`,
    )

    this.thumbs[this._current] &&
      this.thumbs[this._current].setAttribute("data-current", "")
  }

  _setWidthsAndPositions() {
    this.width = parseFloat(
      getComputedStyle(this.element).getPropertyValue("width"),
    )
    this.slider.style.width = `${this.width * this.slides.length}px`
    this.slides.forEach((slide) => {
      slide.style.width = `${this.width}px`
    })
  }
  _setEventListeners() {
    this.thumbs.forEach((thumb, index) => {
      thumb.addEventListener("click", (e) => {
        e.preventDefault()
        this.autoPlay = false
        this.reveal(index)
      })
    })

    // add 'click' to left and right arrow
    this.element.querySelectorAll("[data-go]").forEach((el) => {
      el.addEventListener("click", (e) => {
        e.preventDefault()
        this.reveal(this._current + parseInt(e.currentTarget.dataset.go, 10))
      })
    })

    let events = new SwipeDetector(this.element).emitter

    events.on("left", () => {
      this.reveal(this._current + 1)
    })
    events.on("right", () => {
      this.reveal(this._current - 1)
    })

    window.addEventListener("resize", () => {
      this._setWidthsAndPositions()
      this.reveal(this._current)
    })
  }

  _createThumbs() {
    if (this.thumbsContainer) {
      if (this.slides.length > 1) {
        this.slides.forEach(() => {
          let _node = document.createElement("a")
          _node.setAttribute("href", "")
          _node.setAttribute("data-thumb", "")
          this.thumbsContainer.appendChild(_node)
        })
      }
    }
  }
}
