import { h } from "preact"

export const AwEvents = ({ objects }) => {
  return objects.map((o) => (
    <a
      href={o.url}
      title={o.title}
      id={o.id}
      class="cell medium-6 large-4 page-teaser__card card box"
      key={o.id}
    >
      <div class="card-meta">{o.date_text}</div>
      <div class="card-label">{o.title}</div>
    </a>
  ))
}
