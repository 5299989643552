export class NavbarEffects {
  constructor(navbar) {
    this.effectHeight = 70
    this.effectClass = "scrolled"
    this.toggleClass = "toggled"

    this.scrollEffect(navbar)
    this.navbarToggle(navbar)
  }

  scrollEffect(navbar) {
    window.addEventListener(
      "scroll",
      () => {
        if (window.scrollY > this.effectHeight) {
          navbar.classList.add(this.effectClass)
          document.body.classList.add(this.effectClass)
        } else {
          navbar.classList.remove(this.effectClass)
          document.body.classList.remove(this.effectClass)
        }
      },
      { passive: true },
    )
  }

  navbarToggle(navbar) {
    document
      .querySelector("[data-toggle-mobile-nav]")
      .addEventListener("click", (e) => {
        e.preventDefault()
        navbar.classList.toggle(this.toggleClass)
      })
  }
}
