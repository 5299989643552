/**
 * Handles Ajax requests on JSON endpoints
 */

export class Endpoint {
  constructor(uri) {
    // todo sanitize uri
    this.uri = uri
    this.semaphore = false
    this.buffer = "none"
  }

  getData = async (q = {}) => {
    // console.log(this)
    if (this.semaphore) {
      this.buffer = q
      return false
    }
    this.semaphore = true
    let url = this.uri
    if (q instanceof URLSearchParams) {
      url = `${url}?${q.toString()}`
    }
    return fetch(url).then((response) => {
      let data = {}
      if (this.buffer != "none") {
        let _q = this.buffer
        this.semaphore = false
        this.buffer = "none"
        // console.log("return buffered query")
        data = this.getData(_q)
      } else {
        // console.log("return instant query")
        this.semaphore = false
        data = response.json()
      }
      return data
    })
  }
}
