export class ViewportFadeObserver {
  constructor() {
    const inViewport = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("is-inViewport", entry.isIntersecting)
        }
      })
    }

    const Obs = new IntersectionObserver(inViewport)
    const obsOptions = {} //See: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options

    // Attach observer to every [data-inviewport] element:
    const ELs_inViewport = document.querySelectorAll("[data-appear]")
    ELs_inViewport.forEach((EL) => {
      Obs.observe(EL, obsOptions)
    })
  }
}
