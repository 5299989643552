import { h } from "preact"

export const Jobs = ({ objects }) => {
  const companyShortname = (companyName) => {
    if (companyName.includes("Amstein + Walthert")) {
      if (companyName.includes("Progress")) {
        return "A+W PROGRESS"
      }
      return "A+W"
    }
    return companyName.toUpperCase()
  }
  return objects.map((o) => (
    <a
      class="card box cell small-6 medium-4 large-3 text-left"
      href={o.url}
      key={o.id}
    >
      <div class="card-meta">
        {companyShortname(o.company)} {o.workplace}
      </div>
      <div class="card-label">{o.title}</div>
    </a>
  ))
}
