/**
 * Determines breakpoint
 */

export const breakpoints = () => {
  const bps = [
    ["large", "1024px"],
    ["medium", "640px"],
    ["small", "0px"],
  ]
  return bps.find((bp) => {
    return window.matchMedia(`(min-width: ${bp[1]})`).matches
  })[0]
}
