import { h } from "preact"

export const Articles = (objects) => {
  const IntlDate = new Intl.DateTimeFormat(document.documentElement.lang, {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  })

  const formatDate = (dateStr) => {
    let date = new Date(dateStr)
    return IntlDate.format(date).replace(",", "")
  }

  const formatCategories = (cats) => {
    return cats.join(" / ")
  }

  return objects.map((o) => (
    <div key={o.id} class="article-card cell small-12 medium-6 large-4">
      <a class="article-card__wrap" href={o.url}>
        <div
          class={
            (o.videos.all && "article-card__img article-card__embedded-wrap") ||
            "article-card__img"
          }
        >
          <img src={o.image1} alt={o.name} title={o.name} />
          {o.videos.all && (
            <icon class="icon play-circle article-card__embedded-icon" />
          )}
          <br />
        </div>
        <div class="article-card__meta">
          <span>
            {formatDate(o.publication_date)} / {formatCategories(o.categories)}
          </span>
        </div>
        <div class="article-card__text-wrap">
          <div class="article-card__text-h3">
            <h3>{o.name}</h3>
          </div>
          <div class="article-card__text-p">
            <p>{o.lead_text}</p>
          </div>
        </div>
      </a>
    </div>
  ))
}
