export class ScrollToTopButton {
  constructor(view) {
    this.effectHeight = 1000
    this.effectClass = "hidden"
    window.addEventListener(
      "scroll",
      () => {
        if (window.scrollY < this.effectHeight) {
          view.classList.add(this.effectClass)
        } else {
          view.classList.remove(this.effectClass)
        }
      },
      { passive: true },
    )
    view.addEventListener("click", () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    })
  }
}
