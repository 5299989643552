/**
 *  Competence and reference/projects filtering logic
 */

import { render } from "preact"

import { Articles } from "./cards/articles"

import { Endpoint } from "../utils/endpoint.js"
import { qs } from "../utils/dom"
import { breakpoints } from "../utils/breakpoints"

export class ArticlesView {
  constructor(view) {
    // how many card to show for each breakpoint
    this.pageMap = [
      ["large", 3],
      ["medium", 2],
      ["small", 1],
    ]
    this.view = view
    this.loadNextButton = qs("[data-load-next]", this.view)
    this.loadLastButton = qs("[data-load-last]", this.view)
    this.listEl = qs("[data-list]", this.view)
    this.isFetching = false
    this.caughtError = false
    this.loadMoreRequestTimestamp = null
    this.pages = this.pageReset()
    this.fetchedData = {
      0: { next: `${this.listEl.dataset.list}&paginate_by=${this.pages}` },
    }

    this.currentPage = 0
    this.loadMore(1)

    this.loadNextButton.addEventListener("click", (e) => {
      e.preventDefault()
      this.loadMore(1)
    })

    this.loadLastButton.addEventListener("click", (e) => {
      e.preventDefault()
      this.loadMore(-1)
    })

    // calculate breakpoints on resize and reload list
    window.addEventListener("resize", () => {
      let originalPages = this.pages
      this.pages = this.pageReset()
      if (this.pages != originalPages) {
        this.fetchedData = {
          0: { next: `${this.listEl.dataset.list}&paginate_by=${this.pages}` },
        }
        this.currentPage = 0
        this.loadMore(1)
      }
    })
  }

  async loadMore(step) {
    if (this.isFetching) return
    this.toggleIsFetching(true)
    this.toggleErrorMessage(false)

    const requestTimestamp = new Date().getTime()
    this.loadMoreRequestTimestamp = requestTimestamp

    let nextPage = this.currentPage + step

    if (
      !this.fetchedData[nextPage] &&
      this.fetchedData[this.currentPage].next
    ) {
      let endpoint = new Endpoint(this.fetchedData[this.currentPage].next)

      try {
        const data = await endpoint.getData()

        // ignore the response if the request timestamp is not the same anymore
        // e.g. canceled by the update method
        if (data && this.loadMoreRequestTimestamp === requestTimestamp) {
          this.fetchedData[nextPage] = {
            next: data.pagination.next,
            objects: data.objects,
          }
          this.render(nextPage)
        }
      } catch (err) {
        console.log(err)
        this.toggleErrorMessage(true)
      }
    } else {
      this.render(nextPage)
    }

    this.currentPage = nextPage
    this.updateLoadButtons()
    this.toggleIsFetching(false)
  }

  render(nextPage) {
    const _Articles = Articles(this.fetchedData[nextPage].objects)
    render(_Articles, this.listEl)
  }

  updateLoadButtons() {
    // show/hide next button
    if (this.fetchedData[this.currentPage].next) {
      this.loadNextButton.style.display = "block"
    } else {
      this.loadNextButton.style.display = ""
    }

    // show/hide last button
    if (this.currentPage > 1) {
      this.loadLastButton.style.display = "block"
    } else {
      this.loadLastButton.style.display = ""
    }
  }

  toggleIsFetching(value = false) {
    this.isFetching = value
    this.view.dataset.loading = value ? "filter" : ""
  }

  toggleErrorMessage(force = null) {
    if (force !== null) {
      this.caughtError = force
    } else {
      this.caughtError = !this.caughtError
    }
    this.view.dataset.error = this.caughtError
  }

  pageReset() {
    return this.pageMap.find((p) => {
      return breakpoints() == p[0]
    })[1]
  }
}
